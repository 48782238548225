import "core-js/modules/es.array.push.js";
import { mapGetters } from "vuex";
import speechTextCompVue from "@/components/speechTextComp.vue";
import MarkdownRenderer from "@/components/markdownRenderer.vue";
export default {
  components: {
    speechTextCompVue,
    MarkdownRenderer
  },
  data() {
    return {
      gptModelsVisible: false,
      showLeft: false,
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      chatOptions: {
        sess_name: "",
        page: 1,
        page_num: 20
      },
      selectedChat: {
        s_id: "",
        sess_name: ""
      },
      chatContents: [
        // {
        //   question: "晚上好",
        //   answer: "出错了",
        //   correct: false,
        // },
      ],
      loadPageing: false,
      listIsOver: false,
      contentOptions: {
        page: 1,
        page_num: 3,
        s_id: ""
      },
      gptLoading: false,
      sourceStream: {},
      pageOffsetTop: 0,
      gpt_model_id: this.$store.state.gpt_model_id
    };
  },
  watch: {
    leftPopupVisible: {
      immediate: true,
      handler(val) {
        this.showLeft = val;
      }
    }
  },
  created() {},
  computed: {
    ...mapGetters(["getGptModels", "leftPopupVisible", "getCurrTeam", "getGptModelsChargeRules"]),
    gptModelsActions() {
      const ar = this.getGptModels.map(i => {
        const ai = this.getGptModelsChargeRules.find(j => j.gpt_model_id === i.id);
        return {
          name: i.gpt_model_nickname,
          subname: `1元 = ${ai.wordnum}字`,
          gpt_model_id: i.id
        };
      });
      return ar;
    }
  },
  methods: {
    getChangedModel(id) {
      this.gpt_model_id = id;
    },
    closeAI() {
      this.gptLoading = false;
      if (this.sourceStream && (this.sourceStream.readyState === 0 || this.sourceStream.readyState === 1)) {
        this.sourceStream.close();
      }
    },
    newChat() {
      if (this.gptLoading) {
        this.closeAI();
      }
      this.newChatHandler();
    },
    newChatHandler() {
      this.gpt_model_id = this.$store.state.gpt_model_id;
      this.chatContents = [];
      this.selectedChat = {
        s_id: "",
        sess_name: "新对话"
      };
      this.closeLeftPopup();
    },
    sendMessage(message) {
      let str = message;
      let ar = str.split("");
      let cantsend = ar.every(i => i === "\n");
      if (this.gptLoading || !str || cantsend) {
        return;
      }
      this.gptLoading = true;
      this.chatContents.push({
        question: str,
        answer: "",
        correct: true
      });
      if (this.selectedChat.s_id === "") {
        this.list.unshift({
          s_id: "",
          sess_name: "新对话"
        });
      }
      this.$refs.speechTextComp.clearInput();
      this.sourceStream = {};
      this.$axios.getGptKey({
        text: str
      }).then(async res => {
        this.sourceStream = await this.$axios.chatStreamByLib(res.data, this.selectedChat.s_id || "", "", this.gpt_model_id);
        this.sourceStream.addEventListener("message", e => {
          var ddd = JSON.parse(e.data);
          if (ddd.code === 0) {
            this.chatContents[this.chatContents.length - 1].correct = false;
            this.chatContents[this.chatContents.length - 1].msg = ddd.data;
            this.gptLoading = false;
            this.sourceStream.close();
          } else if (ddd.code === undefined) {
            if (ddd.data === "complete") {
              this.sourceStream.close();
              // this.inputVal = this.inputVal.replace(/^\s*|\s*$/g, "");
              this.chatContents[this.chatContents.length - 1].correct = true;
              this.gptLoading = false;
              if (this.selectedChat.sess_name == "新对话" || this.selectedChat.sess_name.length === 0) {
                this.createChatName({
                  text: str,
                  s_id: ddd.sess_id
                });
              }
            } else if (ddd.data === "sess_id") {
              if (ddd.sess_id && !this.selectedChat.s_id) {
                this.selectedChat.s_id = ddd.sess_id;
                this.list[0].s_id = ddd.sess_id;
              }
            } else {
              this.chatContents[this.chatContents.length - 1].answer += ddd.data.replace(/\\t/g, "\t").replace(/\\n/g, "\n").replace(/\\/g, "");
              this.scrollToBottom();
            }
          }
        });
        this.sourceStream.onerror = error => {
          console.error(error);
          this.sourceStream.close();
          this.gptLoading = false;
        };
      }).catch(error => {
        console.log(error);
        this.chatContents[this.chatContents.length - 1].correct = false;
        this.chatContents[this.chatContents.length - 1].msg = error.data.msg;
        this.gptLoading = false;
        // this.inputVal = this.inputVal.replace(/^\s*|\s*$/g, "");
      });
    },
    createChatName(option) {
      this.$axios.createChatName(option).then(res => {
        console.log(res);
      }).catch(error => {
        console.log(error);
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.pageOffsetTop = document.querySelector(".hiddenBlock").offsetTop;
        document.querySelector(".hiddenBlock").scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
      });
    },
    handleSwipeCellClick(item) {
      this.chatContents = [];
      this.listIsOver = false;
      this.contentOptions.page = 1;
      this.selectedChat = {
        ...item
      };
      this.gpt_model_id = item.gpt_model_id;
      this.contentOptions.s_id = item.s_id;
      this.closeLeftPopup();
      this.getChatHistory(1);
    },
    getChatHistory() {
      this.loadPageing = true;
      this.$axios.getChatHistory(this.contentOptions).then(res => {
        this.loadPageing = false;
        if (res.code === 1) {
          if (res.data.data.length > 0) {
            res.data.data.map(item => {
              this.chatContents.unshift({
                question: item.question,
                answer: item.answer.replace(/\\t/g, "\t").replace(/\\n/g, "\n").replace(/\\/g, ""),
                correct: true
              });
            });
            if (this.contentOptions.page === 1) {
              this.scrollToBottom();
            }
          }
          if (res.data.next_page_url === null) {
            this.listIsOver = true;
          } else {
            this.contentOptions.page++;
            this.listIsOver = false;
          }
        }
      });
    },
    loadMoreContent() {
      this.getChatHistory();
    },
    handleBeforeClose({
      position
    }, item) {
      if (position === "right") {
        this.$confirmDialog({
          title: "提示",
          message: `是否确定删除该对话？`,
          beforeClose: action => {
            if (action === "cancel") {
              return true;
            } else {
              this.$axios.delChatSess({
                s_id: item.id
              }).then(res => {
                if (res.code === 1) {
                  this.list.splice(this.list.findIndex(i => i.id === item.id), 1);
                }
              });
              return true;
            }
          }
        });
        return true;
      }
    },
    closeLeftPopup() {
      this.$store.commit("SET_LEFT_POPUP_VISIBLE", false);
    },
    onRefresh() {
      this.chatOptions = {
        sess_name: "",
        page: 1,
        page_num: 20
      };
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      this.$axios.getChats(this.chatOptions).then(res => {
        if (res.code == 1) {
          if (res.data.data.length > 0) {
            this.list = this.list.concat(res.data.data);
            this.chatOptions.page++;
            this.finished = false;
          } else {
            this.finished = true;
          }
          this.loading = false;
          this.refreshing = false;
        }
      });
    }
  },
  beforeUnmount() {
    this.closeLeftPopup();
  },
  unmounted() {
    this.closeLeftPopup();
  }
};